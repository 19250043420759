import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale("pt-br");
dayjs.extend(localizedFormat);

export const formatDate = (date: string, locale: string) => {
  if (locale === "pt-br") {
    return dayjs(date).format("DD [de] MMM [de] YYYY");
  } else {
    return dayjs(date).format("MMM DD, YYYY");
  }
};
