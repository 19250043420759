import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { subscribeUser } from "@strapi-newsletter/react";
import { Spinner } from "@chakra-ui/react";

import { configFile } from "@/config";

export default function SubscribeBlock() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handeSubscribeSubmit = async (e: any) => {
    e.preventDefault();

    if (!(e.target.email.value === "")) {
      setIsLoading(true);
      try {
        await subscribeUser(
          e.target.email.value,
          `${process.env.NEXT_PUBLIC_API_URL}`
        );
        toast({
          title: "Thank you.",
          description: "We've received your email address.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        e.target.reset();
      } catch (error) {
        setIsLoading(false);
        toast({
          title: "Error!",
          description: "Probably you have already subscribed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      setIsError(e.target.email.value === "");
    }
  };

  return (
    <Box
      as="section"
      width="100%"
      py='80px'
      position="relative"
      bgColor={configFile.primaryAccentColor}
      display='flex'
      justifyContent={'center'}
      alignItems="center"
      flexWrap={'wrap'}
    >
      <Flex
        mx='24px'
        flexDir={{ base: "column", lg: 'row' }}
        gap='16px'
        width={'100%'}
        maxWidth="1216px"
        justifyContent={{ base: 'auto', lg: 'space-between' }}
        alignItems={'center'}
      >
        <Flex
          direction={'column'}
          alignItems='flex-start'
          gap='16px'
          width={{ base: '100%' }}
        >
          <Text
            lineHeight="6"
            fontWeight='semibold'
            fontSize="md"
            color="#FFF"
          >
            RECEBA CONTEÚDOS EM PRIMEIRA MÃO
          </Text>
          <Heading
            size="xl"
            as="h2"
            color="#FFF">
            Inscreva-se na newsletter
          </Heading>
          <Text
            lineHeight="7"
            fontWeight='normal'
            size="lg"
            color={configFile.fifthAccentColor}
          >
            Vamos enviar apenas conteúdos relevantes, prometemos!
          </Text>
        </Flex>
        <Box w='100%'  >
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent={{ lg: 'flex-end' }}
            gap='16px'
          >
            <FormControl isInvalid={isError} onSubmit={(e) => handeSubscribeSubmit(e)}>
              <Input
                variant="Outline"
                size="lg"
                border="1px solid #E2E8F0;"
                name="email"
                placeholder="Digite o seu e-mail"
                type="email"
                autoComplete="on"
              />
              {isError && (
                <FormErrorMessage mb="10px">
                  Email is required.
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              aria-label="Subscribe"
              size="lg"
              bgColor={configFile.fourthAccentColor}
              fontWeight="600"
              color="#fff"
              type="submit"
              maxW={{ lg: '135px' }}
              _hover={{ bgColor: "#FFF", color: configFile.fourthAccentColor }}
            >
              Cadastrar
            </Button>
            {isLoading && (
              <Spinner
                margin=" 0 auto"
                mt="15px"
                color={configFile.primaryAccentColor}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
